import { FunctionComponent } from "react";
import "./activity-tile.scss";

import { NoteIndicator } from "../../shared/note-indicator/note-indicator";
import { ActivityInterface } from "../../../interfaces/activity.interface";

interface IActivityTileProps {
    index: number;
    tileData: { activity: ActivityInterface };
    handleClickTile: (activity: ActivityInterface) => void;
}

export const ActivityTile: FunctionComponent<IActivityTileProps> = (
    props: IActivityTileProps
) => {
    const { tileData, handleClickTile } = props;
    const { activity } = tileData;

    return (
        <span
            className="activity"
            onClick={() => handleClickTile(activity)}
            style={{
                backgroundColor: `#${activity.activityType.backColor}`,
                color: `#${activity.activityType.textColor}`,
            }}
        >
            <div className="activity-text">
                {activity.activityType.shortName}
            </div>
            {activity.memo && <NoteIndicator />}
        </span>
    );
};

